import React from 'react';
import classnames from 'classnames';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { message } from 'antd';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../redux/actions/appActions';

// core components
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar.js';
import Footer from 'components/Footer/Footer.js';

const carouselItems = [
  {
    src: require('assets/img/denys.jpg'),
    altText: 'Slide 1',
    caption: 'Big City Life, United States'
  },
  {
    src: require('assets/img/fabien-bazanegue.jpg'),
    altText: 'Slide 2',
    caption: 'Somewhere Beyond, United States'
  },
  {
    src: require('assets/img/mark-finn.jpg'),
    altText: 'Slide 3',
    caption: 'Stocks, United States'
  }
];

let ps = null;

export default function ContactPage() {
  const accessToken = localStorage.getItem('access_token');
  const [tabs, setTabs] = React.useState(1);
  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const dispatch = useDispatch();
  const [profilePhoto, setProfilePhoto] = React.useState('');
  React.useEffect(() => {
    dispatch(appActions.getProfilePhoto(user_details?.id, setProfilePhoto));
  }, []);
  // const getProfilePhotoState = useSelector((state) => {
  //   console.log("state: ",state)
  //   return state.jamamoApp.profilePhoto?.data;
  // });
  // setProfilePhoto(getProfilePhotoState);

  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.className += ' perfect-scrollbar-on';
      document.documentElement.classList.remove('perfect-scrollbar-off');
      let tables = document.querySelectorAll('.table-responsive');
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.body.classList.toggle('profile-page');
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.documentElement.className += ' perfect-scrollbar-off';
        document.documentElement.classList.remove('perfect-scrollbar-on');
      }
      document.body.classList.toggle('profile-page');
    };
  }, []);

  const [mobile, setMobile] = React.useState('');
  const [comment, setComment] = React.useState('');
  const handleChangemobile = (e) => {
    // console.log("handleChangemobile called=> e.target.name",e.target.name, ", value: ",e.target.value)
    setMobile(e.target.value);
  };

  const handleChangeComment = (e) => {
    // console.log("handleChangeComment called=> e.target.name",e.target.name, ", value: ",e.target.value)
    setComment(e.target.value);
  };
  const [messageApi, contextHolder] = message.useMessage();
  const handleClickFormSubmit = async () => {
    try {
      await messageApi.open({
        type: 'loading',
        content: 'Submit form in progress..',
        duration: 2.5,
        style: {
          marginTop: '20vh'
        }
      });
    } catch (err) {
      //console.log("error: ", err)
    }

    if (!mobile) {
      try {
        await messageApi.open({
          type: 'error',
          content: 'Mobile required',
          duration: 2.5,
          style: {
            marginTop: '20vh'
          }
        });
      } catch (err) {
        //console.log("error: ", err)
      }
      // message.error("Mobile required", 2.5);
      // return;
    }
    //console.log("inside handleClickFormSubmit: email: ",email, ", value.email: ",email)
    if (!comment) {
      try {
        await messageApi.open({
          type: 'error',
          content: 'Message required',
          duration: 2.5,
          style: {
            marginTop: '20vh'
          }
        });
      } catch (err) {
        //console.log("error: ", err)
      }
      //message.error("Message required", 2.5);
      return;
    }
    // else{
    //   const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    //   if (!emailRegex.test(email)) {
    //     message.error("Valid email required", 2.5);
    //     return;
    //   }
    // }
    // if(!verified){
    //   message.error("reCaptcha verification required");
    //   return;
    // }

    // console.log("inside handleClickFormSubmit: fullname: ",full_name, ", value.fullname: ",full_name1)
    // console.log("inside handleClickFormSubmit: email: ",email, ", value.email: ",email1)
    // setSignupLoading(true);

    dispatch(
      appActions.submitContactForm(
        { mobile: mobile, comment: comment },
        handlContactFormComplete
      )
    );
  };
  const handlContactFormComplete = async (status, res) => {
    // setSignupLoading(false);
    if (status) {
     // console.log('handlContactFormComplete success message: ', res);
      const successMessage = 'Contact Form submitted Successfully!';
      //message.success(successMessage);
      try {
        await messageApi.open({
          type: 'success',
          content: successMessage,
          duration: 2.5,
          style: {
            marginTop: '20vh'
          }
        });
      } catch (err) {
        //console.log("error: ", err)
      }
      setMobile('');
      setComment('');
    } else {
      console.log('handlContactFormComplete error message: ', status);
      // message.error(res?.message);
      try {
        await messageApi.open({
          type: 'error',
          content: res?.message,
          duration: 2.5,
          style: {
            marginTop: '20vh'
          }
        });
      } catch (err) {
        //console.log("error: ", err)
      }
    }
  };
  return (
    <>
      {contextHolder}
      <ExamplesNavbar />
      <div className="wrapper">
        <section className="section">
          <Container>
            <Row>
              <Col md="6">
                <Card className="card-plain">
                  <CardHeader>
                    <h1 className="profile-title text-left">Contact Us</h1>
                    <h5 className="text-on-back">.</h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label>Your Name</label>
                            <Input
                              defaultValue={user_details.fullname}
                              type="text"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <label>Email address</label>
                            <Input
                              placeholder={user_details.email}
                              type="email"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label>Mobile</label>
                            <Input
                              value={mobile}
                              defaultValue={user_details.mobile}
                              placeholder="999999999"
                              type="text"
                              onChange={handleChangemobile}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <label>Designation</label>
                            <Input
                              defaultValue={user_details.designation}
                              type="text"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label>Message</label>
                            <Input
                              value={comment}
                              placeholder="Hello there!"
                              type="text"
                              onChange={handleChangeComment}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Button
                        className="btn-round float-right"
                        color="primary"
                        data-placement="right"
                        id="tooltip341148792"
                        type="button"
                        onClick={handleClickFormSubmit}
                      >
                        Submit
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="right"
                        target="tooltip341148792"
                      >
                        Can't wait for your message
                      </UncontrolledTooltip>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col className="ml-auto" md="4">
                <div className="info info-horizontal">
                  <div className="icon icon-primary">
                    <i className="tim-icons icon-square-pin" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Find us at online 24/7</h4>
                    <p>
                      Office: Lakhe <br />
                      Lakhe, Hazaribagh <br />
                      Jharkhand - 825301
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-primary">
                    <i className="tim-icons icon-mobile" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Give us a ring</h4>
                    <p>
                      Support Team <br />
                      +91-6546-313355 <br />
                      +91-7488-367790 <br />
                      support@jamamo.in
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </>
  );
}
