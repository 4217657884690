import React from 'react';
import classnames from 'classnames';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { message } from 'antd';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../redux/actions/appActions';

// core components
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar.js';
import Footer from 'components/Footer/Footer.js';

const carouselItems = [
  {
    src: require('assets/img/denys.jpg'),
    altText: 'Slide 1',
    caption: 'Big City Life, United States'
  },
  {
    src: require('assets/img/fabien-bazanegue.jpg'),
    altText: 'Slide 2',
    caption: 'Somewhere Beyond, United States'
  },
  {
    src: require('assets/img/mark-finn.jpg'),
    altText: 'Slide 3',
    caption: 'Stocks, United States'
  }
];

let ps = null;

export default function BookDemoPage() {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [slotdropdownOpen, setSlotDropdownOpen] = React.useState(false);

  const slottoggle = () => setSlotDropdownOpen((prevState) => !prevState);
  const accessToken = localStorage.getItem('access_token');
  const [tabs, setTabs] = React.useState(1);
  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const dispatch = useDispatch();
  const [profilePhoto, setProfilePhoto] = React.useState('');
  const [courses, setCourses] = React.useState([]);
  const [slots, setSlots] = React.useState([]);
  const [bookedDemo, setBookedDemo] = React.useState([]);
  React.useEffect(() => {
    dispatch(appActions.getCoursesBatchesUpcoming({}, setCourses));
  }, []); 
  React.useEffect(() => {
    dispatch(appActions.getBookedDemo({}, setBookedDemo));
  }, []); 
  React.useEffect(() => {
    dispatch(appActions.getProfilePhoto(user_details?.id, setProfilePhoto));
  }, []); 
  // const getProfilePhotoState = useSelector((state) => {
  //   console.log("state: ",state)
  //   return state.jamamoApp.profilePhoto?.data;
  // });
  // setProfilePhoto(getProfilePhotoState);

  // React.useEffect(() => {
  //   if (navigator.platform.indexOf('Win') > -1) {
  //     document.documentElement.className += ' perfect-scrollbar-on';
  //     document.documentElement.classList.remove('perfect-scrollbar-off'); 
  //     let tables = document.querySelectorAll('.table-responsive');
  //     for (let i = 0; i < tables.length; i++) {
  //       ps = new PerfectScrollbar(tables[i]);
  //     }
  //   }
  //   document.body.classList.toggle('profile-page');
  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     if (navigator.platform.indexOf('Win') > -1) {
  //       ps.destroy();
  //       document.documentElement.className += ' perfect-scrollbar-off';
  //       document.documentElement.classList.remove('perfect-scrollbar-on');
  //     }
  //     document.body.classList.toggle('profile-page');
  //   };
  // }, []);

  const [mobile, setMobile] = React.useState('');
  const [course, setCourse] = React.useState({});
  const [slot, setSlot] = React.useState({});
  const handleChangemobile = (e) => {
    // console.log("handleChangemobile called=> e.target.name",e.target.name, ", value: ",e.target.value)
    setMobile(e.target.value);
  };
  const handleChangeCourse = (e) => {
    // console.log("e.currentTarget.key: ", e.currentTarget.getAttribute("id"));
    // console.log("e.currentTarget: ", e.currentTarget)
    //  console.log("handleChangeCourse called=> e.target.name",e.currentTarget.textContent, ", value: ",e.currentTarget.textContent)
    setCourse(e.currentTarget.textContent);
    courses.map((item)=>{
      if(item?.course_name === e.currentTarget.textContent){
        setSlots(item?.batches);
        setCourse({id: item?.id, course_name: item?.course_name})
        return item?.batches;
      }
    }) || [];
    //console.log("batches: ", batches);
    ;
  };

  const handleChangeSlot = (e) => {
    //console.log("e: ",e);
     // console.log("handleChangeSlot called=> e.target.name",e.currentTarget.textContent, ", value: ",e.currentTarget.textContent)
    slots.map((item)=>{
      if(item?.start_date === e.currentTarget.textContent){
        console.log("item: ",item);
        setSlot({id: item?._id,start_date: item?.start_date});
         return item?._id
      }
    })
  };
  const [messageApi, contextHolder] = message.useMessage();
  const handleClickFormSubmit = async () => {
    try {
      await messageApi.open({
        type: 'loading',
        content: 'Booking Demo in progress..',
        duration: 2.5,
        style: {
          marginTop: '20vh'
        }
      });
    } catch (err) {
      //console.log("error: ", err)
    }

    if (!mobile) {
      try {
        await messageApi.open({
          type: 'error',
          content: 'Mobile required',
          duration: 2.5,
          style: {
            marginTop: '20vh'
          }
        });
      } catch (err) {
        //console.log("error: ", err)
      }
      // message.error("Mobile required", 2.5);
      // return;
    }
    //console.log("inside handleClickFormSubmit: email: ",email, ", value.email: ",email)
    if (!course) {
      try {
        await messageApi.open({
          type: 'error',
          content: 'Course required',
          duration: 2.5,
          style: {
            marginTop: '20vh'
          }
        });
      } catch (err) {
        //console.log("error: ", err)
      }
      //message.error("Message required", 2.5);
      return;
    }
    if (!slot) {
      try {
        await messageApi.open({
          type: 'error',
          content: 'Slot required',
          duration: 2.5,
          style: {
            marginTop: '20vh'
          }
        });
      } catch (err) {
        //console.log("error: ", err)
      }
      //message.error("Message required", 2.5);
      return;
    }
    // else{
    //   const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    //   if (!emailRegex.test(email)) {
    //     message.error("Valid email required", 2.5);
    //     return;
    //   }
    // }
    // if(!verified){
    //   message.error("reCaptcha verification required");
    //   return;
    // }

    // console.log("inside handleClickFormSubmit: fullname: ",full_name, ", value.fullname: ",full_name1)
    // console.log("inside handleClickFormSubmit: email: ",email, ", value.email: ",email1)
    // setSignupLoading(true);
if(mobile && course && slot){
    dispatch(
      appActions.submitDemoForm(
        { mobile: mobile, course: course?.id, slot: slot?.id},
        handlContactFormComplete
      )
    );
      }
  };
  const handlContactFormComplete = async (status, res) => {
    // setSignupLoading(false);
    if (status) {
      //console.log(`status: `, status,' , handlContactFormComplete success message: ', res);
      //message.success(successMessage);
      try {
        await messageApi.open({
          type: status ? 'success' : 'error',
          content: res?.message,
          duration: 2.5,
          style: {
            marginTop: '20vh'
          }
        });
      } catch (err) {
        //console.log("error: ", err)
      }
      setMobile('');
      dispatch(appActions.getBookedDemo({}, setBookedDemo));
    } else {
      //console.log('handlContactFormComplete error message: ', status);
      // message.error(res?.message);
      try {
        await messageApi.open({
          type: 'error',
          content: res?.message,
          duration: 2.5,
          style: {
            marginTop: '20vh'
          }
        });
      } catch (err) {
        //console.log("error: ", err)
      }
    }
  };
  //console.log("courses at map(): ",courses);
  //console.log("slots map(): ",slots);
  return (
    <>
      {contextHolder}
      <ExamplesNavbar />
      <div className="wrapper">
        <section className="section">
          <Container>
            <Row>
              <Col md="6">
                <Card className="card-plain">
                  <CardHeader>
                    <h1 className="profile-title text-left">Book Demo Class (Free)</h1>
                    <h5 className="text-on-back">.</h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label>Your Name</label>
                            <Input
                              defaultValue={user_details.fullname}
                              type="text"
                              required
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <label>Email address</label>
                            <Input
                              placeholder={user_details.email}
                              type="email"
                              required
                              readOnly
                              // disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label>Mobile</label>
                            <Input
                              value={mobile}
                              defaultValue={user_details.mobile}
                              placeholder="999999999"
                              type="text"
                              onChange={handleChangemobile}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <label>Course</label>
                            {/* <Input
                              defaultValue={user_details.designation}
                              type="text"
                              disabled
                            /> */}
                            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction='up'>
        <DropdownToggle key='0' caret>{course?.course_name || `Choose Course`}</DropdownToggle>
        <DropdownMenu >
        {
         
        courses?.length > 0 ?
           courses.map((item)=>{
          return (
            <>
             <DropdownItem name='course' key={item?.id} id={item?.id} onClick={handleChangeCourse}>{item?.course_name}</DropdownItem>
            </> 
          )
        })
        : ''
      }
         
          {/* <DropdownItem></DropdownItem> */}
          
        </DropdownMenu>
        </Dropdown>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label>Select Demo Class Slot</label>
                            <Dropdown isOpen={slotdropdownOpen} toggle={slottoggle} direction='up'>
        <DropdownToggle key='2' caret>{slot?.start_date || `Choose slot`}</DropdownToggle>
        <DropdownMenu >
        {
         slots?.length > 0 ?
            slots.map((item)=>{
           return (
             <>
              <DropdownItem name='slot' key={item?._id} id={item?._id} onClick={handleChangeSlot}>{item?.start_date}</DropdownItem>
             </> 
           )
         })
         : ''
       }
        </DropdownMenu>
        </Dropdown>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Button
                        className="btn-round float-right"
                        color="primary"
                        data-placement="right"
                        id="tooltip341148792"
                        type="button"
                        onClick={handleClickFormSubmit}
                      >
                        Book Demo
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="right"
                        target="tooltip341148792"
                      >
                        Click to book your demo class
                      </UncontrolledTooltip>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col className="ml-auto" md="4">
                <div className="info info-horizontal">
                  <div className="icon icon-primary">
                    <i className="tim-icons icon-square-pin" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Your Upcoming Demo Class</h4>
                    {
                      bookedDemo?.length > 0 ? 
                       bookedDemo.map((item)=>{
                        return  (
                          <>
                          <p>
                          <b>Topic:</b> {item?.batch_name} - {item?.batch_description} <br />
                          <b>From:</b> {item?.start_date} <br />
                          <b>To:</b> {item?.end_date} <br />
                          <b>Duration:</b> {item?.class_duration} minutes <br />
                          ---------------------
                         </p>
                         </>
                        )
                       })
                      : ''
                      }
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-primary">
                    <i className="tim-icons icon-mobile" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Reach out to us if any issue</h4>
                    <p>
                      {/* Support Team <br />
                      +91-6546-313355 <br />
                      +91-7488-367790 <br /> */}
                      support@jamamo.in
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </>
  );
}
