import axios from 'axios';

const baseUrl = process.env.GRAPH_BASE_API_URL;
const headers = {};
if (localStorage.getItem('graph_access_token')) {
  headers.Authorization =
    'Bearer ' + localStorage.getItem('graph_access_token');
}
const graphAxiosInstance = axios.create({
  baseURL: baseUrl,
  headers,
  responseType: 'blob'
});

graphAxiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('graph_access_token');
  config.headers.Authorization = token;
  return config;
});

graphAxiosInstance.interceptors.response.use(
  (response) => {
    return new Promise((resolve, reject) => {
      resolve(response);
    });
  },
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem('graph_access_token');
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export default graphAxiosInstance;
