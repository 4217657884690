import {
  SIGNIN_START,
  SIGNIN_FINISHED,
  SIGNUP_START,
  SIGNUP_FINISHED,
  FETCH_PUBLIC_INFO_START,
  FETCH_PUBLIC_INFO_FINISHED,
  FETCH_USER_PROFILE_PHOTO_START,
  FETCH_USER_PROFILE_PHOTO_FINISHED
} from '../constants/types';

const INITIAL_STATE = {
  profilePhoto: {
    loading: false,
    data: ''
  },
  publicInfo: {
    loading: false,
    data: []
  },
  signupDetails: {
    loading: false,
    data: []
  },
  userDetails: {
    loading: false,
    data: []
  },
  bookedDemo: {
    loading: false,
    data: []
  }
};

const reducer = (state = INITIAL_STATE, action) => {
  //console.log('action::: ', action);
  switch (action.type) {
    case FETCH_USER_PROFILE_PHOTO_START:
      return {
        ...state,
        profilePhoto: { loading: true, data: [] }
      };
    case FETCH_USER_PROFILE_PHOTO_FINISHED:
      return {
        ...state,
        profilePhoto: { loading: false, data: action?.data }
      };
    case FETCH_PUBLIC_INFO_START:
      return {
        ...state,
        publicInfo: { loading: true, data: [] }
      };
    case FETCH_PUBLIC_INFO_FINISHED:
      return {
        ...state,
        publicInfo: { loading: false, data: action?.data }
      };

    case SIGNIN_START:
      return {
        ...state,
        userDetails: {
          loading: true
        }
      };
    case SIGNIN_FINISHED:
      return {
        ...state,
        userDetails: {
          loading: false,
          data: action.data
        }
      };
    case SIGNUP_START:
      return {
        ...state,
        signupDetails: {
          loading: true
        }
      };
    case SIGNUP_FINISHED:
      return {
        ...state,
        signupDetails: {
          loading: false,
          data: action.data
        }
      };
    default:
      return state;
  }
};

export default reducer;
