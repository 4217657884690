import React, { useReducer } from 'react';
import classnames from 'classnames';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../redux/actions/appActions';

// core components
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar.js';
import Footer from 'components/Footer/Footer.js';

const carouselItems = [
  {
    src: require('assets/img/EcommMERN.jpg'),
    altText: 'Slide 1',
    caption: 'MERN Stack: E-Commerce Project'
  },
  {
    src: require('assets/img/TODOMERN.jpg'),
    altText: 'Slide 2',
    caption: 'MERN Stack: TODO Project'
  }
];

let ps = null;

export default function ProfilePage() {
  const [tabs, setTabs] = React.useState(1);
  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const dispatch = useDispatch();
  const [profilePhoto, setProfilePhoto] = React.useState('');
  React.useEffect(() => {
    dispatch(appActions.getProfilePhoto(user_details?.id, setProfilePhoto));
  }, []);
  // const getProfilePhotoState = useSelector((state) => {
  //   console.log("state: ",state)
  //   return state.jamamoApp.profilePhoto?.data;
  // });
  // setProfilePhoto(getProfilePhotoState);

  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      document.documentElement.className += ' perfect-scrollbar-on';
      document.documentElement.classList.remove('perfect-scrollbar-off');
      let tables = document.querySelectorAll('.table-responsive');
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.body.classList.toggle('profile-page');
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.documentElement.className += ' perfect-scrollbar-off';
        document.documentElement.classList.remove('perfect-scrollbar-on');
      }
      document.body.classList.toggle('profile-page');
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <div className="page-header">
          <img
            alt="..."
            className="dots"
            src={require('assets/img/dots.png')}
          />
          <img
            alt="..."
            className="path"
            src={require('assets/img/path4.png')}
          />
          <Container className="align-items-center">
            <Row>
              <Col lg="6" md="6">
                <h1 className="profile-title text-left">
                  {'Hi, ' + user_details?.first_name}
                </h1>
                <h5 className="text-on-back">.</h5>
                {/* <p className="profile-description">
                  Offices parties lasting outward nothing age few resolve.
                  Impression to discretion understood to we interested he
                  excellence. Him remarkably use projection collecting. Going
                  about eat forty world has round miles.
                </p> */}
                <div className="btn-wrapper profile pt-3">
                  <Button
                    className="btn-icon btn-round"
                    color="twitter"
                    href="https://twitter.com/JamamoITSol"
                    id="tooltip639225725"
                    target="_blank"
                    disabled
                  >
                    <i className="fab fa-twitter" />
                  </Button>
                  <UncontrolledTooltip delay={0} target="tooltip639225725">
                    Follow us
                  </UncontrolledTooltip>
                  <Button
                    className="btn-icon btn-round"
                    color="facebook"
                    href="https://www.facebook.com/profile.php?id=61555299735596"
                    id="tooltip982846143"
                    target="_blank"
                    disabled
                  >
                    <i className="fab fa-facebook-square" />
                  </Button>
                  <UncontrolledTooltip delay={0} target="tooltip982846143">
                    Like us
                  </UncontrolledTooltip>
                  <Button
                    className="btn-icon btn-round"
                    color="dribbble"
                    href="https://instagram.com/jamamoitsolutions?igshid=YmMyMTA2M2Y="
                    id="tooltip951161185"
                    target="_blank"
                    disabled
                  >
                    <i className="fab fa-dribbble" />
                  </Button>
                  <UncontrolledTooltip delay={0} target="tooltip951161185">
                    Follow us
                  </UncontrolledTooltip>
                </div>
              </Col>
              <Col className="ml-auto mr-auto" lg="6" md="6">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      alt="..."
                      className="img-center img-fluid rounded-circle"
                      // src={require('assets/img/mike.jpg')}
                      src={
                        profilePhoto
                          ? profilePhoto
                          : require('assets/img/profilePhoto.png')
                      }
                    />
                    <h4 className="title">{user_details?.fullname}</h4>
                  </CardHeader>
                  <CardBody>
                    <Nav
                      className="nav-tabs-primary justify-content-center"
                      tabs
                    >
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: tabs === 1
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            setTabs(1);
                          }}
                          href="#pablo"
                        >
                          Profile
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: tabs === 2
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            setTabs(2);
                          }}
                          href="#pablo"
                        >
                          Chat
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: tabs === 3
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            setTabs(3);
                          }}
                          href="#pablo"
                        >
                          Notification
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent
                      className="tab-subcategories"
                      activeTab={'tab' + tabs}
                    >
                      <TabPane tabId="tab1">
                        <Table className="tablesorter" responsive>
                          <thead className="text-primary">
                            <tr>
                              <th className="header">Name</th>
                              <th className="header">
                                {user_details?.fullname
                                  ? user_details?.fullname
                                  : `Not Available`}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Email</td>
                              <td>
                                {user_details?.email
                                  ? user_details?.email
                                  : `Not Available`}
                              </td>
                            </tr>
                            <tr>
                              <td>Designation</td>
                              <td>
                                {user_details?.designation
                                  ? user_details?.designation
                                  : `Not Available`}
                              </td>
                            </tr>
                            <tr>
                              <td>Department</td>
                              <td>
                                {user_details?.department
                                  ? user_details?.department
                                  : `Not Available`}
                              </td>
                            </tr>
                            <tr>
                              <td>Mobile</td>
                              <td>
                                {user_details?.mobile
                                //  ? user_details?.mobile.slice(0, 2) + user_details?.mobile.slice(2).replace(/.(?=...)/g, '*')  
                                ? user_details?.mobile
                                   : `Not Available`}
                              </td>
                            </tr>
                            <tr>
                              <td>Manager</td>
                              <td>
                                {user_details?.reporting_manager
                                  ? user_details?.reporting_manager
                                  ?.first_name ? user_details?.reporting_manager
                                      ?.first_name +
                                    ' ' +
                                    user_details?.reporting_manager?.last_name
                                  : `` : user_details?.reporting_manager
                                  ?.email}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </TabPane>
                      <TabPane tabId="tab2">
                        <Row>
                          <Label sm="3">Referal ID</Label>
                          <Col sm="9">
                            <FormGroup>
                              <Input
                                placeholder="e.g. 1Nasd92348hU984353hfid"
                                type="text"
                                value={
                                  user_details?.myReferalID
                                    ? user_details?.myReferalID
                                    : ''
                                }
                                disabled
                              />
                              {/* <FormText color="default" tag="span">
                                Please enter a valid address.
                              </FormText> */}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Label sm="3">Chat to</Label>
                          <Col sm="9">
                            <FormGroup>
                              <Input
                                placeholder="name@jamamo.in"
                                type="text"
                                disabled
                              />
                              <FormText color="default" tag="span">
                                Please enter valid email address.
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Button
                          className="btn-simple btn-icon btn-round float-right"
                          color="primary"
                          type="submit"
                          disabled
                        >
                          <i className="tim-icons icon-send" />
                        </Button>
                      </TabPane>
                      <TabPane tabId="tab3">
                        <Table className="tablesorter" responsive>
                          <thead className="text-primary">
                            <tr>
                              <th className="header">Latest Notification</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Nothing to show</td>
                            </tr>
                            {/* <tr>
                              <td>Venezuela Begins Public of Nation...</td>
                            </tr>
                            <tr>
                              <td>PR: BitCanna – Dutch Blockchain...</td>
                            </tr> */}
                          </tbody>
                        </Table>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section">
          <Container>
            <Row className="justify-content-between">
              <Col md="6">
                <Row className="justify-content-between align-items-center">
                  <UncontrolledCarousel items={carouselItems} />
                </Row>
              </Col>
              <Col md="5">
                <h1 className="profile-title text-left">Projects</h1>
                <h5 className="text-on-back">.</h5>
                <p className="profile-description text-left">
                  During Course, we will guide you how to develop real world 
                  application and deployment on EC2 instance.
                </p>
                <div className="btn-wrapper pt-3">
                  <Button
                    className="btn-simple"
                    color="primary"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    disabled
                  >
                    <i className="tim-icons icon-book-bookmark" /> Download Syllabus
                  </Button>
                  {/* <Button
                    className="btn-simple"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    disabled
                  >
                    <i className="tim-icons icon-bulb-63" /> Check it!
                  </Button> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* <section className="section">
          <Container>
            <Row>
              <Col md="6">
                <Card className="card-plain">
                  <CardHeader>
                    <h1 className="profile-title text-left">Contact</h1>
                    <h5 className="text-on-back">.</h5>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label>Your Name</label>
                            <Input defaultValue={user_details.fullname} type="text" disabled/>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <label>Email address</label>
                            <Input placeholder={user_details.email} type="email" disabled/>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label>Phone</label>
                            <Input defaultValue={user_details.mobile} type="text" />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <label>Designation</label>
                            <Input defaultValue={user_details.designation} type="text" disabled/>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label>Message</label>
                            <Input placeholder="Hello there!" type="text" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Button
                        className="btn-round float-right"
                        color="primary"
                        data-placement="right"
                        id="tooltip341148792"
                        type="button"
                      >
                        Submit
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="right"
                        target="tooltip341148792"
                      >
                        Can't wait for your message
                      </UncontrolledTooltip>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col className="ml-auto" md="4">
                <div className="info info-horizontal">
                  <div className="icon icon-primary">
                    <i className="tim-icons icon-square-pin" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Find us at online 24/7</h4>
                    <p>
                      Office: Beside Soni Heights, mission school road, <br />
                      Hurhuru, Hazaribagh <br />
                      Jharkhand - 825301
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-primary">
                    <i className="tim-icons icon-mobile" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Give us a ring</h4>
                    <p>
                      Michael Jordan <br />
                      +91-6546-313355 <br />
                      +91-7488-367790 <br />
                      support@jamamo.in
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section> */}
        <Footer />
      </div>
    </>
  );
}
