// import logo from 'logo.svg';
import 'App.css';
import { connect, useSelector } from 'react-redux';

import { BrowserRouter, Route, Navigate, Routes } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

//import Index from './views/Index.js';
import LandingPage from './views/examples/LandingPage';
import RegisterPage from './views/examples/RegisterPage.js';
import ProfilePage from './views/examples/ProfilePage.js';
import ContactPage from './views/examples/ContactPage.js';
import BookDemoPage from './views/examples/BookDemoPage.js';

const configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    //redirectUri: 'http://localhost:3000/session.html',
    redirectUri: 'https://jamamo.in/session.html'
  },
  cache: {
    cacheLocation: 'localStorage', // This configuress where your cache will be stored
    storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    windowHashTimeout: 60000,
    iframeHashTimeout: 60000,
    loadFrameTimeout: 0,
    asyncPopups: false
  }
};
const pca = new PublicClientApplication(configuration);

function App(props) {
  const showBackground = useSelector((state) => {
    return state?.courses?.showBackground;
  });

  const showHeader = useSelector((state) => {
    return state?.courses?.showHeader;
  });

  const getEmptyArray = (mynumber) => {
    var arr = [];
    for (var i = 1; i <= mynumber; i++) arr.push('' + i);
    return arr;
  };
  const renderDots = (rows, columns) => {
    return (
      <span>
        {getEmptyArray(rows).map((i) => {
          return (
            <div className="d-flex" style={{ marginTop: 12 }}>
              {getEmptyArray(columns).map((j, idx) => (
                <span
                  key={idx}
                  className="dots"
                  style={{ marginLeft: 8 * idx }}
                ></span>
              ))}
            </div>
          );
        })}
      </span>
    );
  };

  const goToHome = () => {
    // navigate('');
    // window.location.href = '';
  };

  const PrivateRoute = ({ children }) => {
    // let location = useLocation();

    const token = localStorage?.getItem('access_token');
    if (!token) {
      return <Navigate to="/" />;
    }

    return children;
  };

  return (
    <div>
      <div className="main-container">
        <BrowserRouter>
          {/* <Routes>
                <Route
                  path="/booktrial"
                  element={
                        <BookTrialPage />
                  }
                /> */}

          <div className="app-content" style={{ flex: 1 }}>
            <Routes>
              <Route
                path="/welcome"
                element={
                  <PrivateRoute>
                    <MsalProvider instance={pca}>
                      <ProfilePage />
                    </MsalProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/"
                element={
                  <MsalProvider instance={pca}>
                    <LandingPage />
                  </MsalProvider>
                }
              />

              <Route path="/welcome" element={<LandingPage />} />
              <Route path="/profile" element={
                <PrivateRoute>
                <MsalProvider instance={pca}>
              <ProfilePage />
              </MsalProvider>
                  </PrivateRoute>
              } />
              <Route path="/contactus" element={
                <PrivateRoute>
                <MsalProvider instance={pca}>
              <ContactPage />
              </MsalProvider>
                  </PrivateRoute>} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/demo" element={<PrivateRoute>
                    <MsalProvider instance={pca}>
                      <BookDemoPage />
                    </MsalProvider>
                  </PrivateRoute>} />
            </Routes>
          </div>
        </BrowserRouter>
      </div>
      {showBackground && (
        <div className="background-shapes">
          <span key="1" className="left half-circle"></span>
          <span key="2" className="left square1"></span>
          <span key="3" className="left square2"></span>
          <span key="4" className="right circle1"></span>
          <span key="5" className="right circle2"></span>
          <span key="6" className="left dots-container">
            {renderDots(4, 2)}
          </span>
          <span key="7" className="right dots-container">
            {renderDots(9, 2)}
          </span>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
