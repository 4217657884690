/*!

=========================================================
* JAMAMO LOAN - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from 'reactstrap';
import LocalStorage from '../../helpers/localStorage';
import { useMsal } from '@azure/msal-react';

export default function ExamplesNavbar(props) {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseOut, setCollapseOut] = React.useState('');
  const [color, setColor] = React.useState('navbar-transparent');
  const user_details = JSON.parse(localStorage.getItem('user_details'));
  //console.log(user_details);
  const { instance } = useMsal();
  const profilePhoto = localStorage.getItem('profilePhotoBlobUrl');
  const signOutClickHandler = () => {
    instance.logoutRedirect({
      account: LocalStorage.homeAccountId,
      postLogoutRedirectUri: '/',
      mainWindowRedirectUri: '/',
      onRedirectNavigate: (url) => {
        console.log(url);
        // Return false if you would like to stop navigation after local logout
        localStorage.clear();
        return true;
      }
    });
  };
  React.useEffect(() => {
    window.addEventListener('scroll', changeColor);
    return function cleanup() {
      window.removeEventListener('scroll', changeColor);
    };
  }, []);
  const changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      setColor('bg-info');
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      setColor('navbar-transparent');
    }
  };
  const toggleCollapse = () => {
    document.documentElement.classList.toggle('nav-open');
    setCollapseOpen(!collapseOpen);
  };
  const onCollapseExiting = () => {
    setCollapseOut('collapsing-out');
  };
  const onCollapseExited = () => {
    setCollapseOut('');
  };
  const handleSignUp = () => {
    props.setSignUp(!props.signup);
    collapseOpen(!collapseOpen);
  };
  return (
    <Navbar className={'fixed-top ' + color} color-on-scroll="100" expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand to="/" id="navbar-brand" tag={Link}>
            <span>JAMAMO IT SOLUTIONS</span>
          </NavbarBrand>
          <UncontrolledTooltip placement="bottom" target="navbar-brand">
            Designed and Coded by Jamamo IT Team
          </UncontrolledTooltip>
          <button
            aria-expanded={collapseOpen}
            className="navbar-toggler navbar-toggler"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className={'justify-content-end ' + collapseOut}
          navbar
          isOpen={collapseOpen}
          onExiting={onCollapseExiting}
          onExited={onCollapseExited}
        >
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  JAMAMO IT SOLUTIONS
                </a>
              </Col>
              <Col className="collapse-close text-right" xs="6">
                <button
                  aria-expanded={collapseOpen}
                  className="navbar-toggler"
                  onClick={toggleCollapse}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href="ttps://twitter.com/JamamoITSol"
                rel="noopener noreferrer"
                target="_blank"
                title="Follow us on Twitter"
              >
                <i className="fab fa-twitter" />
                <p className="d-lg-none d-xl-none">Twitter</p>
              </NavLink>
            </NavItem>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href="https://www.facebook.com/profile.php?id=61555299735596"
                rel="noopener noreferrer"
                target="_blank"
                title="Like us on Facebook"
              >
                <i className="fab fa-facebook-square" />
                <p className="d-lg-none d-xl-none">Facebook</p>
              </NavLink>
            </NavItem>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href="https://instagram.com/jamamoitsolutions?igshid=YmMyMTA2M2Y="
                rel="noopener noreferrer"
                target="_blank"
                title="Follow us on Instagram"
              >
                <i className="fab fa-instagram" />
                <p className="d-lg-none d-xl-none">Instagram</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/">
                Home
              </NavLink>
            </NavItem>
            {!user_details ? (
              <>
              <NavItem>
                <Button
                  className="nav-link d-block d-lg-block"
                  // className="nav-link d-none d-lg-block"
                  color="primary"
                  target="_blank"
                  href="/register"
                >
                  {/* <NavLink tag={Link} to="/register_page"> */}
                  <i className="tim-icons icon-spaceship" /> Sign up
                  {/* </NavLink> */}
                </Button>
              </NavItem>
                 <NavItem>
                 <Button
                   className="nav-link d-block d-lg-block"
                   // className="nav-link d-none d-lg-block"
                   color="primary"
                  // target="_blank"
                   href="/demo"
                 >
                   {/* <NavLink tag={Link} to="/register_page"> */}
                   <i className="tim-icons icon-calender" /> Book Demo
                   {/* </NavLink> */}
                 </Button>
               </NavItem>
               </>
            ) : (
              ''
            )}
            {user_details ? (
              <>
              <NavItem>
                <NavLink tag={Link} to="/profile">
                  {/* <i className="tim-icons icon-spaceship" /> */}
                  <img
                    alt="..."
                    className="img-center img-fluid rounded-circle"
                    style={{ width: '25px', marginRight: '5px' }}
                    //  src={require('assets/img/mike.jpg')}
                    src={
                      profilePhoto
                        ? profilePhoto
                        : require('assets/img/profilePhoto.png')
                    }
                  />
                  {'   ' + user_details.fullname}
                </NavLink>
              </NavItem>
              <NavItem>
                 <Button
                   className="nav-link d-block d-lg-block"
                   // className="nav-link d-none d-lg-block"
                   color="primary"
                  // target="_blank"
                   href="/demo"
                 >
                   {/* <NavLink tag={Link} to="/register_page"> */}
                   <i className="tim-icons icon-calender" /> Book Demo
                   {/* </NavLink> */}
                 </Button>
               </NavItem>
               </>
            ) : (
              <NavItem>
                <NavLink tag={Link} onClick={props.loginbutton}>
                  Login
                </NavLink>
              </NavItem>
            )}

            <NavItem>
              <NavLink tag={Link} to="/contactus">
                Contact us
              </NavLink>
            </NavItem>
            {user_details ? (
              <NavItem>
                <Button
                  className="nav-link d-lg-block"
                  style={{ padding: '10px' }}
                  color="primary"
                  target="_blank"
                  //  href="/register"
                  onClick={() => signOutClickHandler()}
                >
                  {/* <NavLink tag={Link} to="/register_page"> */}
                  <i className="tim-icons icon-spaceship" />
                  Logout
                  {/* </NavLink> */}
                </Button>
              </NavItem>
            ) : (
              ''
            )}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}
