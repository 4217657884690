import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
// react plugin used to create charts
import { Line } from 'react-chartjs-2';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col
} from 'reactstrap';

// core components
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar.js';
import Footer from 'components/Footer/Footer.js';

import bigChartData from 'variables/charts.js';
import changeDocumentTitle from '../../helpers/changeDocumentTitle';
import appActions from '../../redux/actions/appActions';

export default function LandingPage() {
  const [signup, setSignUp] = useState(false);
  const [errorMsg] = useState('');
  changeDocumentTitle('JAMAMO: HOME');
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const accessToken = localStorage.getItem('access_token');

  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken) {
      dispatch(
        appActions.getPublicInfo({
          token: ''
        })
      );
      //   navigate('/profile_page');
    } else {
      //dispatch({ type: UPDATE_SHOW_HEADER, show: false });
      dispatch(
        appActions.getPublicInfo({
          token: ''
        })
      );
    }
  }, []);

  useEffect(() => {
    document.body.classList.toggle('landing-page');
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle('landing-page');
    };
  }, []);
  const loginCallback = (success, userDetails) => {
    if (success) {
      localStorage.setItem('access_token', userDetails?.token);
      localStorage.setItem(
        'user_details',
        JSON.stringify(userDetails?.user_details || '{}')
      );
      const prevPath = localStorage.getItem('path_before_login');
      if (prevPath) {
        navigate(prevPath);
        localStorage.removeItem('path_before_login');
      } else {
        navigate('/demo');
      }

      //message.success('Welcome to JAMAMO IT SOLUTIONS');
      try {
        messageApi.open({
          type: 'success',
          content: 'Welcome to JAMAMO IT SOLUTIONS',
          duration: 2.5,
          style: {
            marginTop: '20vh'
          }
        });
      } catch (err) {
        //console.log("error: ", err)
      }
    } else {
      // message.error('Failure: Something went wrong. We are looking into it');
      try {
        messageApi.open({
          type: 'error',
          content: 'Failed to Login!',
          duration: 2.5,
          style: {
            marginTop: '20vh'
          }
        });
      } catch (err) {
        //console.log("error: ", err)
      }
    }
  };

  const publicInfo = useSelector((state) => {
    return state?.jamamoApp?.publicInfo;
  });
  return (
    <>
      {contextHolder}
      <ExamplesNavbar
        signup={signup}
        setSignUp={setSignUp}
        loginbutton={() => {
          instance
            .loginPopup({
              scopes: ['User.Read']
            })
            .then((result) => {
              localStorage.setItem(
                'graph_access_token',
                result.accessToken || ''
              );
              return dispatch(
                appActions.signin(
                  {
                    token: result.accessToken
                  },
                  loginCallback
                )
              );
            })
            .then((result) => {
              if (result.payload.success) {
                localStorage.setItem(
                  'JITS_USER',
                  JSON.stringify(result.payload)
                );
                const user = result.payload;
                if (user.user_details.role === 'user') {
                } else if (user.user_details.role === 'admin') {
                }
                //   history.push('/users');
              } else {
                //  console.log('$$$$$$$$$$$$$$$$$$$');
              }
            })
            .catch((err) => {});
        }}
      />
      <div className="wrapper">
        <div className="page-header">
          <img
            alt="..."
            className="path"
            src={require('assets/img/blob.png')}
          />
          <img
            alt="..."
            className="path2"
            src={require('assets/img/path2.png')}
          />
          <img
            alt="..."
            className="shapes triangle"
            src={require('assets/img/triunghiuri.png')}
          />
          <img
            alt="..."
            className="shapes wave"
            src={require('assets/img/waves.png')}
          />
          <img
            alt="..."
            className="shapes squares"
            src={require('assets/img/patrat.png')}
          />
          <img
            alt="..."
            className="shapes circle"
            src={require('assets/img/cercuri.png')}
          />
          <div className="content-center">
            <Row className="row-grid justify-content-between align-items-center text-left">
              <Col lg="6" md="6">
                <h1 className="text-white">
                  Welcome to Jamamo <br />
                  <span className="text-white">IT Solutions</span>
                </h1>
                <p className="text-white mb-3">
                  Jamamo IT Solutions is an online coding learning platform for
                  students, working tech professionals or from any stream. Our
                  job-oriented training makes you perfect and helps in unlocking
                  talent, skills & opportunities in career.
                </p>
                <div className="btn-wrapper mb-3">
                  <p className="category text-success d-inline">Explore Apps</p>
                  <Button
                    className="btn-link"
                    color="success"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    <i className="tim-icons icon-minimal-right" />
                  </Button>
                </div>
                <div className="btn-wrapper">
                  <div className="button-container">
                    <Button
                      className="btn-icon btn-simple btn-round btn-neutral"
                      color="default"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-twitter" />
                    </Button>
                    <Button
                      className="btn-icon btn-simple btn-round btn-neutral"
                      color="default"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-dribbble" />
                    </Button>
                    <Button
                      className="btn-icon btn-simple btn-round btn-neutral"
                      color="default"
                      href="https://www.facebook.com/profile.php?id=61555299735596"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-facebook" />
                    </Button>
                  </div>
                </div>
              </Col>
              <Col lg="4" md="5">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require('assets/img/jits_logo.png')}
                />
              </Col>
            </Row>
          </div>
        </div>
        <section className="section section-lg">
          <section className="section">
            <img
              alt="..."
              className="path"
              src={require('assets/img/path4.png')}
            />
            <Container>
              <Row className="row-grid justify-content-between">
                <Col className="mt-lg-5" md="5">
                  <Row>
                    <Col className="px-2 py-2" lg="6" sm="12">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                                <i className="tim-icons icon-trophy text-warning" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <CardTitle tag="p">
                                  {publicInfo?.data?.apps_count || 100}+
                                </CardTitle>
                                <p />
                                <p className="card-category">Apps</p>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col className="px-2 py-2" lg="6" sm="12">
                      <Card className="card-stats upper bg-default">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                                <i className="tim-icons icon-coins text-white" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <CardTitle tag="p">
                                  {publicInfo?.data?.users_count || 100}+
                                </CardTitle>
                                <p />
                                <p className="card-category">Users</p>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-2 py-2" lg="6" sm="12">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                                <i className="tim-icons icon-gift-2 text-info" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <CardTitle tag="p">
                                  {publicInfo?.data?.courses_count || 100}+
                                </CardTitle>
                                <p />
                                <p className="card-category">Courses</p>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col className="px-2 py-2" lg="6" sm="12">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                                <i className="tim-icons icon-credit-card text-success" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <CardTitle tag="p">10,783</CardTitle>
                                <p />
                                <p className="card-category">Forks</p>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    <h1>
                      Our <br />
                      Achivements
                    </h1>
                    <p>
                      I should be capable of drawing a single stroke at the
                      present moment; and yet I feel that I never was a greater
                      artist than now.
                    </p>
                    <br />
                    <p>
                      Unlocking Futures, One Lesson at a Time- Empowering Minds
                      through Education Technology Excellence.
                    </p>
                    <br />
                    <a
                      className="font-weight-bold text-info mt-5"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      About us{' '}
                      <i className="tim-icons icon-minimal-right text-info" />
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </section>
        <section className="section section-lg">
          <img
            alt="..."
            className="path"
            src={require('assets/img/path4.png')}
          />
          <img
            alt="..."
            className="path2"
            src={require('assets/img/path5.png')}
          />
          <img
            alt="..."
            className="path3"
            src={require('assets/img/path2.png')}
          />
          <Container>
            <Row className="justify-content-center">
              <Col lg="12">
                <h1 className="text-center">Our best benefit</h1>
                <Row className="row-grid justify-content-center">
                  <Col lg="3">
                    <div className="info">
                      <div className="icon icon-primary">
                        <i className="tim-icons icon-money-coins" />
                      </div>
                      <h4 className="info-title">Responsive Design:</h4>
                      <hr className="line-primary" />
                      <p>
                        Ensure that your website is optimized for various
                        devices and screen sizes.
                      </p>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="info">
                      <div className="icon icon-warning">
                        <i className="tim-icons icon-chart-pie-36" />
                      </div>
                      <h4 className="info-title">Speed and Performance:</h4>
                      <hr className="line-warning" />
                      <p>
                        Showcase the fast loading times and overall performance
                        of your website. Provide a brief description of the main
                        content or services offered on your website.
                      </p>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="info">
                      <div className="icon icon-success">
                        <i className="tim-icons icon-single-02" />
                      </div>
                      <h4 className="info-title">Feedback Mechanism:</h4>
                      <hr className="line-success" />
                      <p>
                        Include a way for users to provide feedback on the
                        website's content or functionality. Provide clear and
                        accessible contact information for user inquiries or
                        support.
                      </p>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="info">
                      <div className="icon icon-success">
                        <i className="tim-icons icon-single-02" />
                      </div>
                      <h4 className="info-title">Future Developments:</h4>
                      <hr className="line-success" />
                      <p>
                        Provide insights into future website developments,
                        features, or content updates. If applicable, showcase
                        any community or user engagement features on the
                        website.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg section-safe">
          <img
            alt="..."
            className="path"
            src={require('assets/img/path5.png')}
          />
          <Container>
            <Row className="row-grid justify-content-between">
              <Col md="5">
                <img
                  alt="..."
                  className="img-fluid floating"
                  src={require('assets/img/chester-wade.jpg')}
                />
                <Card className="card-stats bg-danger">
                  <CardBody>
                    <div className="justify-content-center">
                      <div className="numbers">
                        <CardTitle tag="p">50+</CardTitle>
                        <p className="card-category text-white">Projects</p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="card-stats bg-info">
                  <CardBody>
                    <div className="justify-content-center">
                      <div className="numbers">
                        <CardTitle tag="p">25+</CardTitle>
                        <p className="card-category text-white">
                          Satisfied customers
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="card-stats bg-default">
                  <CardBody>
                    <div className="justify-content-center">
                      <div className="numbers">
                        <CardTitle tag="p">6+</CardTitle>
                        <p className="card-category text-white">
                          Year Of Experience
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <div className="px-md-5">
                  <hr className="line-success" />
                  <h3>What We offer</h3>
                  <p>
                    Our vision is to deliver unsurpassed IT solutions to our
                    potential prospects for their exact requirements. Being one
                    of the pioneers in the Software Industry, all our services
                    together delivers and abreast premium values & integrity.
                  </p>
                  <ul className="list-unstyled mt-5">
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div className="icon icon-success mb-2">
                          <i className="tim-icons icon-vector" />
                        </div>
                        <div className="ml-3">
                          <h6>
                            To deliver exact outcomes to our clientele needs
                            through our innovative technology solutions.
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div className="icon icon-success mb-2">
                          <i className="tim-icons icon-tap-02" />
                        </div>
                        <div className="ml-3">
                          <h6>
                            When our clients grow in their business, we believe
                            we are growing a step further.
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div className="icon icon-success mb-2">
                          <i className="tim-icons icon-single-02" />
                        </div>
                        <div className="ml-3">
                          <h6>
                            To spread ourselves across globally and help our
                            customers with our high-quality solutions.
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div className="icon icon-success mb-2">
                          <i className="tim-icons icon-single-02" />
                        </div>
                        <div className="ml-3">
                          <h6>
                            To be the partner-of-choice for best-in-class
                            software service provider.
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div className="icon icon-success mb-2">
                          <i className="tim-icons icon-single-02" />
                        </div>
                        <div className="ml-3">
                          <h6>
                            To make our every solutions & product inspired by
                            people.
                          </h6>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <section className="section section-lg">
          <img
            alt="..."
            className="path"
            src={require('assets/img/path4.png')}
          />
          <img
            alt="..."
            className="path2"
            src={require('assets/img/path2.png')}
          />
          <Col md="12">
            <Card className="card-chart card-plain">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <hr className="line-info" />
                    <h5 className="card-category">Total Investments</h5>
                    <CardTitle tag="h2">Performance</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={bigChartData.data}
                    options={bigChartData.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </section> */}
        <section className="section section-lg section-coins">
          <img
            alt="..."
            className="path"
            src={require('assets/img/path3.png')}
          />
          <Container>
            <Row>
              <Col md="4">
                <hr className="line-info" />
                <h1>
                  Choose the app{' '}
                  <span className="text-info">that fits your needs</span>
                </h1>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require('assets/img/jits_logo.png')}
                    />
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-center" md="12">
                        <h4 className="text-uppercase">Jamamo Learning</h4>
                        <span>Education</span>
                        <hr className="line-primary" />
                      </Col>
                    </Row>
                    <Row>
                      <ListGroup>
                        <ListGroupItem>Learn Coding MERN Stack</ListGroupItem>
                        <ListGroupItem>10+ milestones</ListGroupItem>
                        <ListGroupItem>100+ live breakouts</ListGroupItem>
                        <ListGroupItem>All Lecture recordings</ListGroupItem>
                        <ListGroupItem>
                          Lifetime career assistance
                        </ListGroupItem>
                        <ListGroupItem>24/7 Community Support</ListGroupItem>
                        <ListGroupItem>24/7 Customer Support</ListGroupItem>
                        {/* <ListGroupItem>Fee: Rs 14999 only</ListGroupItem> */}
                      </ListGroup>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      href="https://learning.jamamo.in"
                      className="btn-simple"
                      color="primary"
                      disabled
                    >
                      Open App
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require('assets/img/jits_logo.png')}
                    />
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-center" md="12">
                        <h4 className="text-uppercase">Jamamo Foodie</h4>
                        <span>Food & Grossary Delivery</span>
                        <hr className="line-success" />
                      </Col>
                    </Row>
                    <Row>
                      <ListGroup>
                        <ListGroupItem>Restaurant Discovery</ListGroupItem>
                        <ListGroupItem>Food Ordering</ListGroupItem>
                        <ListGroupItem>Quick Delivery</ListGroupItem>
                        <ListGroupItem>Multiple payment options</ListGroupItem>
                        <ListGroupItem>Real-time Tracking</ListGroupItem>
                        <ListGroupItem>Real-time Tracking</ListGroupItem>
                        <ListGroupItem>24/7 Customer Support</ListGroupItem>
                      </ListGroup>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button className="btn-simple" color="success" disabled>
                      Upcoming App
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require('assets/img/jits_logo.png')}
                    />
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-center" md="12">
                        <h4 className="text-uppercase">JAMAMO LOAN</h4>
                        <span>Finance</span>
                        <hr className="line-info" />
                      </Col>
                    </Row>
                    <Row>
                      <ListGroup>
                        <ListGroupItem>Personal loan upto 1 Lakh</ListGroupItem>
                        <ListGroupItem>Online Application</ListGroupItem>
                        <ListGroupItem>Quick Approval Process</ListGroupItem>
                        <ListGroupItem>Low Interest Rates</ListGroupItem>
                        <ListGroupItem>Minimal Documentation</ListGroupItem>
                        <ListGroupItem>
                          Flexible Repayment Options
                        </ListGroupItem>
                        <ListGroupItem>24/7 Customer Support</ListGroupItem>
                      </ListGroup>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button className="btn-simple" color="info" disabled>
                      Upcoming App
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            {/* <Row className='app_row'>
              <Col md="4">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require('assets/img/jits_logo.png')}
                    />
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-center" md="12">
                        <h4 className="text-uppercase">Jamamo Learning</h4>
                        <span>Education</span>
                        <hr className="line-primary" />
                      </Col>
                    </Row>
                    <Row>
                      <ListGroup>
                        <ListGroupItem>Learn Coding MERN Stack</ListGroupItem>
                        <ListGroupItem>10+ milestones</ListGroupItem>
                        <ListGroupItem>100+ live breakouts</ListGroupItem>
                        <ListGroupItem>Class recordings</ListGroupItem>
                        <ListGroupItem>Lifetime career assistance</ListGroupItem>
                        <ListGroupItem>24/7 Community Support</ListGroupItem>
                        <ListGroupItem>24/7 Customer Support</ListGroupItem>
                        <ListGroupItem>Fee: Rs 6000 only</ListGroupItem>
                      </ListGroup>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button className="btn-simple" color="primary">
                      Open App
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require('assets/img/jits_logo.png')}
                    />
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-center" md="12">
                        <h4 className="text-uppercase">Jamamo Foodie</h4>
                        <span>Food & Grossary Delivery</span>
                        <hr className="line-success" />
                      </Col>
                    </Row>
                    <Row>
                      <ListGroup>
                       <ListGroupItem>Restaurant Discovery</ListGroupItem>
                        <ListGroupItem>Food Ordering</ListGroupItem>
                        <ListGroupItem>Quick Delivery</ListGroupItem>
                        <ListGroupItem>Multiple payment options</ListGroupItem>
                        <ListGroupItem>Real-time Tracking</ListGroupItem>
                        <ListGroupItem>Real-time Tracking</ListGroupItem>
                        <ListGroupItem>24/7 Customer Support</ListGroupItem>
                      </ListGroup>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button className="btn-simple" color="success" disabled>
                    Upcoming App
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require('assets/img/jits_logo.png')}
                    />
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-center" md="12">
                        <h4 className="text-uppercase">JAMAMO LOAN</h4>
                        <span>Finance</span>
                        <hr className="line-info" />
                      </Col>
                    </Row>
                    <Row>
                      <ListGroup>
                      <ListGroupItem>Personal loan upto 1 Lakh</ListGroupItem>
                        <ListGroupItem>Online Application</ListGroupItem>
                        <ListGroupItem>Quick Approval Process</ListGroupItem>
                        <ListGroupItem>Low Interest Rates</ListGroupItem>
                        <ListGroupItem>Minimal Documentation</ListGroupItem>
                        <ListGroupItem>Flexible Repayment Options</ListGroupItem>
                        <ListGroupItem>24/7 Customer Support</ListGroupItem>
                      </ListGroup>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button className="btn-simple" color="info" disabled>
                      Upcoming App
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row> */}
          </Container>
          <Container>
          <iframe title="ads" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid061u2mM8iByHkVQMChQrbxEnA1XwBYBJBzM2XxR5Jdd9QkqTJK5px8S3Xt16RNcM1l%26id%3D61555299735596&show_text=true&width=100%" width="100%" height="611" style={{"border":"none","overflow":"hidden"}} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </Container>
        </section>
        <Footer />
      </div>
    </>
  );
}
