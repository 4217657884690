import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from 'reactstrap';

import { message } from 'antd';
// core components
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar.js';
import Footer from 'components/Footer/Footer.js';
import changeDocumentTitle from '../../helpers/changeDocumentTitle';
import appActions from '../../redux/actions/appActions';

export default function RegisterPage() {
  const [squares1to6, setSquares1to6] = React.useState('');
  const [squares7and8, setSquares7and8] = React.useState('');
  const [fullNameFocus, setFullNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [passwordFocus, setPasswordFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.toggle('register-page');
    document.documentElement.addEventListener('mousemove', followCursor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle('register-page');
      document.documentElement.removeEventListener('mousemove', followCursor);
    };
  }, []);
  const followCursor = (event) => {
    let posX = event.clientX - window.innerWidth / 2;
    let posY = event.clientY - window.innerWidth / 6;
    setSquares1to6(
      'perspective(500px) rotateY(' +
        posX * 0.05 +
        'deg) rotateX(' +
        posY * -0.05 +
        'deg)'
    );
    setSquares7and8(
      'perspective(500px) rotateY(' +
        posX * 0.02 +
        'deg) rotateX(' +
        posY * -0.02 +
        'deg)'
    );
  };
  const [full_name, setFull_name] = React.useState('');
  const [email, setEmail] = React.useState('');
  const handleChangeFullname = (e) => {
    // console.log("handleChangeFullname called=> e.target.name",e.target.name, ", value: ",e.target.value)
    setFull_name(e.target.value);
  };

  const handleChangeEmail = (e) => {
    // console.log("handleChangeEmail called=> e.target.name",e.target.name, ", value: ",e.target.value)
    setEmail(e.target.value);
  };
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const handleCreateAccount = async () => {
    try {
      await messageApi.open({
        type: 'loading',
        content: 'Creating account in progress..',
        duration: 2.5,
        style: {
          marginTop: '20vh'
        }
      });
    } catch (err) {
      //console.log("error: ", err)
    }

    if (!full_name) {
      // message.error("Full name required", 2.5);
      try {
        await messageApi.open({
          type: 'error',
          content: 'Full name required',
          duration: 2.5,
          style: {
            marginTop: '20vh'
          }
        });
      } catch (err) {
        //console.log("error: ", err)
      }
      // return;
    }
    //console.log("inside handleCreateAccount: email: ",email, ", value.email: ",email)
    if (!email) {
      // message.error("Email required", 2.5);
      try {
        await messageApi.open({
          type: 'error',
          content: 'Email required',
          duration: 2.5,
          style: {
            marginTop: '20vh'
          }
        });
      } catch (err) {
        //console.log("error: ", err)
      }
      return;
    } else {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(email)) {
        message.error('Valid email required', 2.5);
        return;
      }
    }
    // if(!verified){
    //   message.error("reCaptcha verification required");
    //   return;
    // }

    // console.log("inside handleCreateAccount: fullname: ",full_name, ", value.fullname: ",full_name1)
    // console.log("inside handleCreateAccount: email: ",email, ", value.email: ",email1)
    // setSignupLoading(true);

    dispatch(
      appActions.signup(
        { full_name: full_name, email: email },
        handlSignupComplete
      )
    );
  };
  const handlSignupComplete = async (status, res) => {
    // setSignupLoading(false);
    if (status) {
      console.log('handlSignupComplete success message: ', res);
      console.log('inviteUrl: ', res?.invite_details?.inviteRedeemUrl);
      //   {
      //     "inviteRedeemUrl": "https://login.microsoftonline.com/redeem?rd=https%3a%2f%2finvitations.microsoft.com%2fredeem%2f%3ftenant%3dfdd9f042-2064-4553-96f7-810892eb2d70%26user%3db2926565-e7a7-4fe8-af12-27851cc44b05%26ticket%3dNDhHVe2PlrSmURf%25252b33WpQpcc0pyL30de3URjjz3qOtM%25253d%26ver%3d2.0",
      //     "user_id": "2512ff55-13b9-41a6-a26d-6b7f9116f6dc",
      //     "email": "vikramraihzb@gmail.com"
      // }/////////////
      const successMessage = 'Registration completed Successfully!';
      // message.success(successMessage);
      try {
        await messageApi.open({
          type: 'success',
          content: successMessage,
          duration: 2.5,
          style: {
            marginTop: '20vh'
          }
        });
      } catch (err) {
        //console.log("error: ", err)
      }
      setFull_name('');
      setEmail('');
      //dispatch(appActions.getPermittedCoursesList());
      // navigate('https://google.com');
      window.location.replace(res?.invite_details?.inviteRedeemUrl);
    } else {
      console.log('handlSignupComplete error message: ', status);
      // message.error(res?.message);
      try {
        await messageApi.open({
          type: 'error',
          content: res?.message,
          duration: 2.5,
          style: {
            marginTop: '20vh'
          }
        });
      } catch (err) {
        //console.log("error: ", err)
      }
    }
  };
  return (
    <>
      {contextHolder}
      <ExamplesNavbar />
      <div className="wrapper">
        <div className="page-header">
          <div className="page-header-image" />
          <div className="content">
            <Container>
              <Row>
                <Col className="offset-lg-0 offset-md-3" lg="5" md="6">
                  <div
                    className="square square-7"
                    id="square7"
                    style={{ transform: squares7and8 }}
                  />
                  <div
                    className="square square-8"
                    id="square8"
                    style={{ transform: squares7and8 }}
                  />
                  <Card className="card-register">
                    <CardHeader>
                      <CardImg
                        alt="..."
                        src={require('assets/img/square-purple-1.png')}
                      />
                      <CardTitle tag="h4"> Register</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Form className="form">
                        <InputGroup
                          className={classnames({
                            'input-group-focus': fullNameFocus
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Full Name"
                            type="text"
                            onFocus={(e) => setFullNameFocus(true)}
                            onBlur={(e) => setFullNameFocus(false)}
                            onChange={handleChangeFullname}
                          />
                        </InputGroup>
                        <InputGroup
                          className={classnames({
                            'input-group-focus': emailFocus
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            type="text"
                            onFocus={(e) => setEmailFocus(true)}
                            onBlur={(e) => setEmailFocus(false)}
                            onChange={handleChangeEmail}
                          />
                        </InputGroup>
                        {/* <InputGroup
                          className={classnames({
                            'input-group-focus': passwordFocus
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-lock-circle" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Password"
                            type="text"
                            onFocus={(e) => setPasswordFocus(true)}
                            onBlur={(e) => setPasswordFocus(false)}
                          />
                        </InputGroup> */}
                        <FormGroup check className="text-left">
                          <Label check>
                            <Input type="checkbox" />
                            <span className="form-check-sign" />I agree to the{' '}
                            <a
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              terms and conditions
                            </a>
                            .
                          </Label>
                        </FormGroup>
                      </Form>
                    </CardBody>
                    <CardFooter>
                      <Button
                        onClick={handleCreateAccount}
                        className="btn-round"
                        color="primary"
                        size="lg"
                      >
                        Submit
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
              <div className="register-bg" />
              <div
                className="square square-1"
                id="square1"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-2"
                id="square2"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-3"
                id="square3"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-4"
                id="square4"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-5"
                id="square5"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-6"
                id="square6"
                style={{ transform: squares1to6 }}
              />
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
