import React from 'react';
import ReactDOM from 'react-dom/client';

import 'assets/css/nucleo-icons.css';
import 'assets/scss/blk-design-system-react.scss';
import 'assets/demo/demo.css';

import App from './App';
// REDUX
import { Provider } from 'react-redux';
import store from './redux/store.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
