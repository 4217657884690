import {
  FETCH_USER_PROFILE_PHOTO_START,
  FETCH_USER_PROFILE_PHOTO_FINISHED,
  FETCH_PUBLIC_INFO_START,
  FETCH_PUBLIC_INFO_FINISHED,
  SIGNIN_FINISHED,
  SIGNIN_START,
  SIGNUP_START,
  SIGNUP_FINISHED,
  SUBMIT_CONTACT_FORM_START,
  SUBMIT_CONTACT_FORM_FINISHED,
  SUBMIT_DEMO_FORM_START,
  SUBMIT_DEMO_FORM_FINISHED,
  FETCH_COURSE_BATCH_START,
  FETCH_COURSE_BATCH_FINISHED,
  FETCH_BOOKED_DEMO_START,
  FETCH_BOOKED_DEMO_FINISHED
} from '../constants/types';

import axiosInstance from '../../helpers/axios';
import graphAxiosInstance from '../../helpers/graph_axios';
import axios from 'axios';
import { API_BASE_URL, GRAPH_BASE_API_URL } from '../../constants/appConstants';
import { requestsData } from './test_data';

const getPublicInfo = (payoad, callback) => (dispatch) => {
  dispatch({ type: FETCH_PUBLIC_INFO_START });
  axios.post(API_BASE_URL + 'auth/getPublicInfo', payoad).then(
    (res) => {
      dispatch({ type: FETCH_PUBLIC_INFO_FINISHED, data: res.data });
      //console.log("user/getPublicInfo api: ",res.data)
      callback && callback(true, res.data);
    },
    (err) => {
      console.log('error', err);
      dispatch({ type: FETCH_PUBLIC_INFO_FINISHED, data: {} });
      callback && callback(false);
    }
  );
};
const submitContactForm = (payload, callback) => (dispatch) => {
  dispatch({ type: SUBMIT_CONTACT_FORM_START });
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('access_token')
  };
  axios
    .post(API_BASE_URL + 'contact/submitContactForm', payload, { headers })
    .then(
      (res) => {
        dispatch({ type: SUBMIT_CONTACT_FORM_FINISHED, data: res.data });
        console.log('contact/submitContactForm api: ', res.data);
        callback && callback(true, res.data);
      },
      (err) => {
        console.log('error', err);
        dispatch({ type: SUBMIT_CONTACT_FORM_FINISHED, data: {} });
        callback && callback(false);
      }
    );
};

const submitDemoForm = (payload, callback) => (dispatch) => {
  dispatch({ type: SUBMIT_DEMO_FORM_START });
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('access_token')
  };
  axios
    .post(API_BASE_URL + 'user/demo/submitDemoForm', payload, { headers })
    .then(
      (res) => {
        dispatch({ type: SUBMIT_DEMO_FORM_FINISHED, data: res.data });
        console.log('user/demo/submitDemoForm api: ', res.data);
        callback && callback(true, res.data);
      },
      (err) => {
        console.log('error', err);
        dispatch({ type: SUBMIT_DEMO_FORM_FINISHED, data: {} });
        callback && callback(false);
      }
    );
};

const getCoursesBatchesUpcoming = (payload, callback) => (dispatch) => {
  dispatch({ type: FETCH_COURSE_BATCH_START });
  axiosInstance
    .get(API_BASE_URL + 'user/demo/getCoursesBatchesUpcoming', payload)
    .then(
      (res) => {
        dispatch({ type: FETCH_COURSE_BATCH_FINISHED, data: res?.data });
        console.log('user/demo/getCoursesBatchesUpcoming api: ', res?.data);
        callback && callback(res?.data?.data);
      },
      (err) => {
        console.log('error', err);
        dispatch({ type: FETCH_COURSE_BATCH_FINISHED, data: {} }); 
        callback && callback(false);
      }
    );
};

const getBookedDemo = (payload, callback) => (dispatch) => {
  dispatch({ type: FETCH_BOOKED_DEMO_START });
  axiosInstance
    .get(API_BASE_URL + 'user/demo/getBookedDemo', payload)
    .then(
      (res) => {
        dispatch({ type: FETCH_BOOKED_DEMO_FINISHED, data: res?.data });
        console.log('user/demo/getBookedDemo api: ', res?.data);
        callback && callback(res?.data?.data);
      },
      (err) => {
        console.log('error', err);
        dispatch({ type: FETCH_BOOKED_DEMO_FINISHED, data: {} }); 
        callback && callback(false);
      }
    );
};

const getProfilePhoto = (payoad, callback) => (dispatch) => {
  dispatch({ type: FETCH_USER_PROFILE_PHOTO_START });
  graphAxiosInstance
    .get(
      `https://graph.microsoft.com/v1.0/users/${payoad}/photos/240x240/$value`,
      {}
    )
    .then(
      (res) => {
        const url = window.URL || window.webkitURL;
        let blobUrl;
        try {
          localStorage.removeItem('profilePhotoBlobUrl');
        } catch (err) {
          console.log('error: ', err);
        }
        try {
          blobUrl = url.createObjectURL(res?.data);
          localStorage.setItem('profilePhotoBlobUrl', blobUrl);
        } catch (err) {
          blobUrl = localStorage.getItem('profilePhotoBlobUrl', blobUrl) || '';
        }
        dispatch({ type: FETCH_USER_PROFILE_PHOTO_FINISHED, data: blobUrl });
        // console.log("users/994419a3-1d70-47de-a0cc-0315767b0aa7/photos/100x100/$value api: ", blobUrl)
        callback && callback(blobUrl);
      },
      (err) => {
        console.log('error1', err);
        dispatch({ type: FETCH_USER_PROFILE_PHOTO_FINISHED, data: '' });
        callback && callback(false);
      }
    );
};

const signin = (payoad, callback) => (dispatch) => {
  dispatch({ type: SIGNIN_START });
  axios.post(API_BASE_URL + 'auth/signin', payoad).then(
    (res) => {
      dispatch({ type: SIGNIN_FINISHED, data: res.data });
      callback && callback(true, res.data);
    },
    (err) => {
      console.log('error', err);
      dispatch({ type: SIGNIN_FINISHED, data: {} });
      callback && callback(false);
    }
  );
};

const signup = (payoad, callback) => (dispatch) => {
  dispatch({ type: SIGNUP_START });
  axios.post(API_BASE_URL + 'user/invite', payoad).then(
    (res) => {
      console.log('action success signup', res?.data);
      dispatch({ type: SIGNUP_FINISHED, data: res?.data });
      callback && callback(true, res?.data);
    },
    async (err) => {
      console.log('action error signup', err);
      dispatch({ type: SIGNUP_FINISHED, data: {} });
      callback && callback(false, err?.response?.data);
    }
  );
};

const appActions = {
  signin,
  signup,
  getPublicInfo,
  getProfilePhoto,
  submitContactForm,
  getCoursesBatchesUpcoming,
  submitDemoForm,
  getBookedDemo
};

export default appActions;
