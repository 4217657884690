export const FETCH_USER_PROFILE_PHOTO_START = 'FETCH_USER_PROFILE_PHOTO_START';
export const FETCH_USER_PROFILE_PHOTO_FINISHED =
  'FETCH_USER_PROFILE_PHOTO_FINISHED';

export const FETCH_PUBLIC_INFO_START = 'FETCH_PUBLIC_INFO_START';
export const FETCH_PUBLIC_INFO_FINISHED = 'FETCH_PUBLIC_INFO_FINISHED';

export const GET_PROFILE_START = 'GET_PROFILE_START';
export const GET_PROFILE_FINISHED = 'GET_PROFILE_FINISHED';

export const SIGNIN_START = 'SIGNIN_START';
export const SIGNIN_FINISHED = 'SIGNIN_FINISHED';

export const SIGNUP_START = 'SIGNUP_START';
export const SIGNUP_FINISHED = 'SIGNUP_FINISHED';

export const SUBMIT_CONTACT_FORM_START = 'SUBMIT_CONTACT_FORM_START';
export const SUBMIT_CONTACT_FORM_FINISHED = 'SUBMIT_CONTACT_FORM_FINISHED';

export const SUBMIT_DEMO_FORM_START = 'SUBMIT_DEMO_FORM_START';
export const SUBMIT_DEMO_FORM_FINISHED = 'SUBMIT_DEMO_FORM_FINISHED';

export const FETCH_COURSE_BATCH_START = 'FETCH_COURSE_BATCH_START';
export const FETCH_COURSE_BATCH_FINISHED = 'FETCH_COURSE_BATCH_FINISHED';

export const FETCH_BOOKED_DEMO_START = 'FETCH_BOOKED_DEMO_START';
export const FETCH_BOOKED_DEMO_FINISHED = 'FETCH_BOOKED_DEMO_FINISHED';


